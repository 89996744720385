/**
 * @author Shier
 * @createTime 2023/8/24 星期四 9:01
 * 权限校验值
 */
const ACCESS_ENUM = {
  NOT_LOGIN: "notLogin",
  USER: "user",
  ADMIN: "admin",
};

export default ACCESS_ENUM;
